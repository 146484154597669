// AIzaSyBJb7SNWWKTD3fli_jy8juizrynp0SzTYI
import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
} from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { MarkerFF } from "@react-google-maps/api";

import "./Checkout.scss";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import check from "../../Assets/check-circle.png";
import arrowLeft from "../../Assets/Images/arrowLeft.svg";

function Map({ setSelectedLocation, selectedLocation }) {
  const mapRef = useRef();
  const markerRef = useRef();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBJb7SNWWKTD3fli_jy8juizrynp0SzTYI",
    libraries: ["places"],
  });

  const handleMapClick = (event) => {
    setSelectedLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  const handleMarkerDragEnd = (event) => {
    setSelectedLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  const handleMapDrag = () => {
    // console.log(0);
    const map = mapRef.current;
    const marker = markerRef.current;

    if (map && marker) {
      const center = map.getCenter();
      marker.setPosition(center);
      setSelectedLocation({
        lat: center.lat(),
        lng: center.lng(),
      });
    }
  };

  if (!isLoaded) return "Loading...";

  return (
    <GoogleMap
      zoom={13.5}
      center={selectedLocation || { lat: 0, lng: 0 }} // add a default position
      mapContainerClassName="map-container"
      onClick={handleMapClick}
      onDrag={handleMapDrag}
      ref={mapRef}
    >
      {selectedLocation && (
        <MarkerF
          position={selectedLocation}
          draggable={true}
          onDragEnd={handleMarkerDragEnd}
          ref={markerRef}
        />
      )}
    </GoogleMap>
  );
}

const Checkout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [cartItems, setCartItems] = useState(location.state.cartItems);
  const pharmacyLocation = location.state.pharmacyLocation;
  const [city, setCity] = useState("");
  const subTotal = location.state.total;
  const deliveryAndServiceFees1 = 1800;
  const deliveryAndServiceFees2 = 2400;
  const [total, setTotal] = useState("");
  const [temp, setTemp] = useState(false);
  const pharmacyId = location.state.pharmacyId;
  const pharmacyName = location.state.pharmacyName;
  const orderId = generateOrderId();
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [imgStr, setImgStr] = useState([]);
  const prescription = location.state.prescription;

  const [selectedLocation, setSelectedLocation] = useState({
    lat: 15.576173856238315,
    lng: 32.5684761777892,
  });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBJb7SNWWKTD3fli_jy8juizrynp0SzTYI",
    libraries: ["places"],
  });

  console.log(location);

  const handleClose = () => {
    setShow(false);
  };

  function generateOrderId() {
    const timestamp = Date.now();
    const random = (timestamp * Math.floor(Math.random() * 100000)) % 100000;
    return `${random}`;
  }

  const uploadImage = (img, idx) => {
    let resImg = null;
    const imgAPIKey = "826fbb1f90dacfa942f721a496d71950";
    let formData = new FormData();
    formData.append("image", img);
    const url = `https://api.imgbb.com/1/upload?key=${imgAPIKey}`;
    console.log(formData);
    fetch(url, {
      method: "POST",
      body:
        // JSON.stringify({
        formData,
      // })
    })
      .then((res) => res.json())
      .then((result) => {
        resImg = result.data.display_url;
        console.log(resImg);
        console.log("imgbb", result);
        let arr = imgStr;
        arr[idx] = resImg;
        setImgStr(arr);
        console.log(imgStr);
        return resImg;
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // let arr = data;
    imgStr["prescription"] = imgStr["0"];
    // data = { ...arr, ...imgStr };

    setLoading(true);
    const order = {
      id: orderId,
      name: data.name,
      phone: data.phone,
      city: data.city,
      address: data.address,
      cartItems: cartItems,
      total: total,
      pharmacy: pharmacyId,
      pharmacyName: location.state.pharmacyName,
      status: "placed",
      selectedLocation: selectedLocation,
      prescription: imgStr["prescription"],
    };

    try {
      const response = await axios.post(
        "https://elaj-server.herokuapp.com/api/orders/create",
        order
      );
      console.log(response.data);
      setLoading(false);
      setShow(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    pharmacyLocation == city
      ? setTotal(subTotal + deliveryAndServiceFees1)
      : setTotal(subTotal + deliveryAndServiceFees2);
    console.log(total);
  }, [temp]);
  return (
    <div className="checkout-page">
      <Modal show={show} onHide={handleClose}>
        {!loading ? (
          <Modal.Body>
            <img src={check} alt="" />
            <p>Your order is confirmed. We will contact you shortly!</p>
            <p>تم الطلب! سيتم التواصل معك في أقرب وقت</p>
            <button onClick={() => navigate("/")} className="confirm">
              Confirm
            </button>
          </Modal.Body>
        ) : (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Loading</Modal.Title>
            </Modal.Header>
            <div className="loading lds-hourglass"></div>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
      <div className="title-bar content__padding">
        <button onClick={() => navigate(-1)}>
          <img src={arrowLeft} alt="" />
        </button>
        <h2 className="title">Checkout</h2>
      </div>

      <form
        className="checkout-cart container"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row">
          <div className="col">
            <label>Name - الاسم</label>
            <br />
            <input
              type="text"
              placeholder="Type..."
              id="name"
              {...register("name", { min: 6, maxLength: 24, required: true })}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label>Phone - رقم الهاتف</label> <br />
            <input
              type="number"
              placeholder="Type..."
              id="phone"
              {...register("phone", { required: true })}
            />
          </div>
        </div>

        {prescription && (
          <div className="row">
            <div className="col">
              <label>Photo of prescription - برجاء تصوير الروشتة</label> <br />
              <input
                type="file"
                id="pres"
                onChange={(e) => {
                  uploadImage(e.target.files[0], 0);
                  console.log(imgStr);
                }}
                // {...register("phone", { required: true })}
              />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col">
            <label>City - المدينة</label> <br />
            <select
              {...register("city", { required: true })}
              onChange={(e) => {
                setCity(e.target.value);
                setTemp(!temp);
              }}
            >
              <option label="Choose..."></option>
              <option value="Khartoum">Khartoum</option>
              <option value="Bahri">Bahri</option>
              <option value="Omdurman">Omdurman</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <label>Address - العنوان</label> <br />
            <input
              type="text"
              placeholder="Type..."
              id="address"
              {...register("address", { required: true })}
            />
          </div>
        </div>

        {!isLoaded ? (
          <div className="">Loading</div>
        ) : (
          <Map
            setSelectedLocation={setSelectedLocation}
            selectedLocation={selectedLocation}
          />
        )}

        <div className="items container">
          <div className="row">
            <div className="col item">Subtotal</div>
            <div className="col item">
              {subTotal} <strong>SDG</strong>
            </div>
          </div>
          <div className="row">
            <div className="col item">Delivery & Services</div>

            <div className="col item">
              {pharmacyLocation == city ? (
                <>
                  {deliveryAndServiceFees1} <strong>SDG</strong>
                </>
              ) : (
                <>
                  {deliveryAndServiceFees2} <strong>SDG</strong>
                </>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col item">Total</div>
            <div className="col item">
              {total ? (
                <p style={{ fontSize: "18px", color: "blue" }}>
                  <strong>{total}</strong>
                </p>
              ) : (
                <>
                  We will contact you
                  <br />
                  سنتواصل معكم للمبلغ الإجمالي
                </>
              )}{" "}
              <strong>SDG</strong>
            </div>
          </div>
          {pharmacyLocation != city ? (
            <div className="row">
              <div
                style={{ textAlign: "center", color: "red" }}
                className="col"
              >
                Crossing a bridge قاطع كبري
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="row">
            <h5 style={{ textAlign: "center", marginTop: "24px" }} className="">
              للدفع مقدم عن طريق بنكك
            </h5>
            <p style={{ marginBottom: "0", textAlign: "center" }}>
              أمين محمد السيد الزبير
            </p>
            <p
              style={{
                marginBottom: "0",
                textAlign: "center",
                color: "red",
                fontWeight: "600",
              }}
            >
              1849502
            </p>
          </div>
        </div>
        <input className="next-btn" type="submit" />
      </form>
    </div>
  );
};

export default Checkout;
