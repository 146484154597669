import React, { useState, useEffect } from "react";
import "./ProductCardList.scss";
import ProductCard from "../ProductCard/ProductCard";
import back from "../../Assets/Images/back.svg";

const ProductCardList = (props) => {
  const [itemsByCategory, setItemsByCategory] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    // Use reduce to group items by category
    const groupedItems = props.list.reduce((accumulator, currentItem) => {
      const category = currentItem.category.toLowerCase().trim();
      if (!accumulator[category]) {
        // If the category doesn't exist yet, create an empty array
        accumulator[category] = [];
      }
      accumulator[category].push(currentItem);
      return accumulator;
    }, {});
    setItemsByCategory(groupedItems);
  }, [props.list]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedItems(itemsByCategory[category]);
  };

  const handleBackClick = () => {
    setSelectedCategory("");
    setSelectedItems([]);
  };

  if (selectedCategory) {
    return (
      <div className="product-card-list">
        <div className="bar container"></div>
        <div className="cat content__padding" id={selectedCategory}>
          <div className="temp">
            <button onClick={handleBackClick} className="back-button">
              <img src={back} alt="" />
            </button>
            <h2 className="category" style={{ margin: "0px" }}>
              {selectedCategory.charAt(0).toUpperCase() +
                selectedCategory.slice(1)}
            </h2>
          </div>

          {selectedItems.map((item) => (
            <ProductCard
              key={item.id}
              product={item}
              addToCart={props.addToCart}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="product-card-list">
      <div className="category-grid content__padding">
        {Object.keys(itemsByCategory)
          .sort()
          .map((category) => (
            <button
              key={category}
              onClick={() => handleCategoryClick(category)}
            >
              <div className="cat-box">
                <h2 className="cat-text">
                  {category
                    .split(" ")
                    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
                    .join(" ")}
                </h2>
              </div>
            </button>
          ))}
      </div>
    </div>
  );
};

export default ProductCardList;
