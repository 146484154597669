import React from "react";
import "./Cart.scss";
import pot from "../../Assets/Images/pot.svg";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import deleteBtn from "../../Assets/delete.svg";

function Cart({
  cartItems,
  removeFromCart,
  pharmacyId,
  pharmacyName,
  pharmacyLocation,
}) {
  const total = cartItems.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );

  return (
    <div className="cart-page">
      <h2>Cart</h2>
      {cartItems.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <div className="cart content__padding">
          {cartItems.map((item) => (
            <div key={item.id} className="cart-item">
              <img className="cart-item-img" src={item.image} alt={item.name} />
              <div className="cart-item-details">
                <div className="cart-item-name">{item.name}</div>
                <div className="cart-item-price">
                  {item.price ? `SDG ${item.price.toFixed(0)}` : 0}
                </div>
              </div>
              <div className="cart-item-quantity">x {item.quantity}</div>
              <button
                className="remove-btn"
                onClick={() => removeFromCart(item)}
              >
                <img src={deleteBtn} alt="delete button" />
              </button>
            </div>
          ))}
          <div>
            <strong>Total: SDG {total.toFixed(2)}</strong>
          </div>
          <div className="content__padding">
            <button className="cart-btn">
              <Link
                to={{
                  pathname: "/checkout",
                }}
                state={{
                  cartItems,
                  total,
                  pharmacyId,
                  pharmacyName,
                  pharmacyLocation,
                }}
                className="cart-btn"
              >
                <img src={pot} alt="" />
                Checkout
              </Link>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Cart;
