import React from "react";
import "./PharmacyCard.scss";

const PharmacyCard = (props) => {
  const pharmacyName = props.title
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return (
    <a className="pharmacy-card" href={`${props.link}`}>
      <div
        style={{ backgroundImage: `url(${props.header})` }}
        className="card-header"
      ></div>
      <div className="card-body content__padding">
        {/* <div className="card-image"> */}
        <img className="card-image" src={props.image} alt="" />
        <p className="card-description city">{props.city}</p>
        {/* </div> */}
        <h1 className="card-title">{pharmacyName}</h1>
        <p className="card-description">{props.description}</p>
      </div>
    </a>
  );
};

export default PharmacyCard;
