import React, { useEffect, useState, Suspense } from "react";
import "./PharmacyCardList.scss";
import "../PharmacyCard/PharmacyCard";
import axios from "axios";

import logo from "../../Assets/Images/logo.svg";
import Her from "../../Assets/Images/Categories/Her.png";
import Her2 from "../../Assets/Images/Categories/Her2.png";
import Pills from "../../Assets/Images/Categories/Pills.png";
import General from "../../Assets/Images/Categories/General.png";
import PharmacyCard from "../PharmacyCard/PharmacyCard";

const PharmacyCardList = () => {
  const [loading, setLoading] = useState(true);
  const [pharmacies, setPharmacies] = useState([]);
  const [originalPharmacies, setOriginalPharmacies] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    axios
      .get(`https://elaj-server.herokuapp.com/api/account/getall`)
      .then((res) => {
        setPharmacies(res.data);
        setOriginalPharmacies(res.data);
        setLoading(false);
      });
  }, []);

  const filterCity = (selectedCity) => {
    const filteredPharmacies = originalPharmacies.filter((pharmacy) => {
      return (
        pharmacy.city === selectedCity &&
        pharmacy.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    setPharmacies(filteredPharmacies);
  };

  const filteredPharmacies = pharmacies.filter((pharmacy) =>
    pharmacy.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="pharmacy-card-list">
      <div className="header">
        <img className="logo" src={logo} alt="" />
      </div>
      <input
        type="search"
        className="content__padding"
        placeholder="Search by name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      <div
        className="categories content__padding"
        style={{ marginBottom: "12px" }}
      >
        <button onClick={() => setPharmacies(originalPharmacies)}>
          الكل
          <br />
          All
        </button>
        <button onClick={() => filterCity("Bahri")}>
          بحري
          <br />
          Bahri
        </button>
        <button onClick={() => filterCity("Khartoum")}>
          الخرطوم
          <br />
          Khartoum
        </button>
        <button onClick={() => filterCity("Omdurman")}>
          أمدرمان
          <br />
          Omdurman
        </button>
      </div>
      <br />
      {/* <div className="cat-cards">
        <img src={Her} alt="" />
        <img src={Pills} alt="" />
        <img src={General} alt="" />
      </div> */}
      <div className="her">
        <img src={Her2} alt="" />
      </div>

      {loading && (
        <>
          <br />
          <br />
          <br />
          <div className="lds-hourglass"></div>
        </>
      )}

      <Suspense fallback={<div class="lds-hourglass">Loading</div>}>
        <div className="list">
          {filteredPharmacies &&
            filteredPharmacies
              .slice(0)
              .map(
                (pharmacy, idx) =>
                  pharmacy.approved && (
                    <PharmacyCard
                      key={idx}
                      title={pharmacy.name}
                      description={pharmacy.description}
                      image={pharmacy.profilePic}
                      header={pharmacy.headerPic}
                      link={`/pharmacy/${pharmacy._id}`}
                      id={pharmacy._id}
                      city={pharmacy.city}
                    />
                  )
              )}
        </div>
      </Suspense>

      <div className="promo">
        <h2 className="header">عايز تزود صيدليتك؟</h2>
        <h2 className="sub">
          بنقدم الخدمة مجانا مدى الحياة لأول صيدليات تدخل معانا
        </h2>
        <h2 className="sub">
          instagram: <a href="https://instagram.com/elajsudan">@elajsd</a>
        </h2>
      </div>
      <div className="nav-bar"></div>
    </div>
  );
};

export default PharmacyCardList;
