import logo from "./logo.svg";
import "./App.scss";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Navigate,
  Routes,
} from "react-router-dom";

import {
  Pharmacies,
  Products,
  Profile,
  Checkout,
  Orders,
  Cart,
} from "./Pages/index";
import ProductCardList from "./Components/ProductCardList/ProductCardList";
import PharmacyCardList from "./Components/PharmacyCardList/PharmacyCardList";
import { useEffect } from "react";

function App() {
  // useEffect(() => {
  //   alert("تنبيه، الخدمة متوقفة حاليا نظرا لما تمر به البلاد من عدم استقرار");
  //   alert(
  //     "Orders & delivery are stopped due to the current instability in Sudan"
  //   );
  // }, []);
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<PharmacyCardList />} />
          <Route path="/home" element={<PharmacyCardList />} />
          <Route path="/pharmacy/:id" element={<Products />} />
          {/* <Route path="/checkout" component={Checkout} /> */}
          <Route
            path="/checkout"
            element={<Checkout />}
            render={(props) => <Checkout {...props} />}
          ></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
